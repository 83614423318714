import dotenv from 'dotenv';
import path from 'path';

dotenv.config({ path: path.resolve(process.cwd(), '../.env') });

export default {
  server: process.env.VUE_APP_IOTSERVER,
  cache: process.env.VUE_APP_CACHESERVER,
  gateways: {
    server: process.env.VUE_APP_GATEWAYSERVER,
    key: process.env.VUE_APP_GATEWAYKEY
  }
};

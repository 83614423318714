import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: Cookies.get('auth')
  },
  mutations: {
    setAuth: (state, auth) => {
      Cookies.set('auth', auth);

      state.auth = auth;
    }
  },
  getters: {
    auth: (state) => state.auth
  }
});

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Waiting from '@/views/Waiting.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:auth',
    name: 'Key',
    component: Waiting
  }
];

const router = new VueRouter({
  routes
});

export default router;

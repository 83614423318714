<template>
  <v-app class="mainova">
    <v-main>
      <v-container class="pa-0" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    if (this.$route.params.auth) {
      this.$store.commit('setAuth', this.$route.params.auth);
      this.$router.replace('/');
    }
  }
};
</script>

<style lang="scss">
.mainova {
  height: 100vh;
  overflow: hidden;
}

html, body {
  height: 100vh;
  overflow: hidden !important;
}
</style>

import axios from 'axios';
import moment from 'moment';
import * as turf from '@turf/turf';
import config from './config.js';
import createHash from 'create-hash/browser.js';

const bad = 'rgb(183, 28, 28)';

export const log = (...args) => {
  // eslint-disable-next-line no-console
  console.log(moment().format('DD.MM.YYYY HH:mm:ss'), ...args);
};

export const promisify = (func) => (...args) => {
  return new Promise((resolve, error) => {
    func(args, (e, o) => {
      if (e) {
        error(e);
      } else {
        resolve(o);
      }
    });
  });
};

export const getData = async(data, auth) => {
  try {
    let res = await axios.post(`${config.server}/rssi`, data, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    });

    return res.data.result;
  } catch (e) {
    console.error(e);
  }
  
  return [];
};

export const getSelectedCache = async(id) => {
  try {
    let res = await axios(`${config.cache}/${id}-iso-outdoor.json`, {
      headers: {
        Authorization: `Bearer ${config.gateways.key}`
      }
    });

    if (res.data) {
      return turf.featureCollection(res.data.features);
    }
  } catch (e) {
    //
  }

  return [];
};

export const getCachedLocations = async(id) => {
  try {
    let res = await axios(`${config.cache}/${id}-location-outdoor.json`, {
      headers: {
        Authorization: `Bearer ${config.gateways.key}`
      }
    });

    if (res.data) {
      return turf.featureCollection(res.data.features);
    }
  } catch (e) {
    //
  }

  return [];
};

const getCache = async(params) => {
  if (typeof fs !== 'undefined') {
    let key = JSON.stringify(params);

    let hash = createHash('sha512');
    hash.update(key, 'utf8');
    let name = hash.digest('hex');

    try {
      let file = await global.fs.readFile(`./cache/${name}.json`, { encoding: 'utf8' });
      let content = JSON.parse(file);

      return content;
    } catch (e) {
      return null;
    }
  }

  return null;
};

const setCache = async(params, data) => {
  if (typeof fs !== 'undefined') {
    let key = JSON.stringify(params);

    let hash = createHash('sha512');
    hash.update(key, 'utf8');
    let name = hash.digest('hex');

    await global.fs.writeFile(`./cache/${name}.json`, JSON.stringify(data));
  }
};

export const getRSSI = async(auth, days, location, filter = [], include = [], progress = () => {}) => {
  let result = [];
  
  try {
    for (let i = 1; i <= days; i++) {
      let data = {
        after: moment().subtract(i, 'days').startOf('day').toISOString(),
        before: moment().subtract(i, 'days').endOf('day').toISOString(),
        type: location,
        onlyMeasuredLocation: location === 'outdoor',
        filter,
        include
      };

      let cached = await getCache(data);
      let res;

      if (cached) {
        res = cached;
      } else {
        res = (await getData(data, auth)).filter(e => e.device_location);
        await setCache(data, res);
      }

      if (typeof fs !== 'undefined') {
        log(`Location: ${location} - ${i} von ${days}`); // eslint-disable-line
      }

      progress(Math.round(i / days * 100));
  
      result.push(...res);
    }

    return result;
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const getLocationSource = (rssi, settings, gateways = [], filterGateways = []) => {
  if (rssi === null) {
    return turf.featureCollection([]);
  }
  
  let points = rssi
    .filter(e => {
      if (gateways.filter(g => !filterGateways.includes(g.id)).length === 1 && settings.showExclusiveMeasurements) {
        return e.fullStats.cnt === 1;
      }

      return true;
    })
    .map((location, i) => {
      let color = 'blue';

      if (gateways.filter(g => !filterGateways.includes(g.id)).length === 1 && location.fullStats.cnt === 1) {
        color = 'red';
      }

      let props = {
        name: location.device_name,
        rssi: location.stats.rssi,
        measured_at: location.measured_at,
        gateways: location.gateway_stats.map((gw) => {
          let gateway = gateways.find(g => g.id === gw.router_id_hex || g.id2 === gw.router_id_hex);

          if (gateway) {
            return {
              coordinates: gateway.coordinates,
              name: gateway.name,
              rssi: gw.rssi,
              snr: gw.snr,
              eui: gw.router_id_hex
            };
          }

          return null;
        }).filter(e => !!e),
        description: `
          <b>Lat:</b> ${location.device_location[1]}<br />
          <b>Lng:</b> ${location.device_location[0]}<br />
          <b>RSSI:</b> ${location.stats.rssi} dBm<br />
          <b>SNR:</b> ${location.stats.snr} dB<br />
          <b>SF:</b> ${location.sf}<br />
          <b>Packet:</b> ${location.packet_id}<br />
          <b>Device ID:</b> ${location.device_id}<br />
          <b>Device Name:</b> ${location.device_name}<br />
          <b>Datum:</b> ${moment(location.measured_at).format('DD.MM.YYYY HH:mm:ss')}<br />
          <b>Mandant:</b> ${location.mandate}<br />
        `,
        color,
        id: i,
        'stroke-color': 'rgba(0, 0, 0, 0.5)'
      };
      return turf.point(location.device_location, props);
    });
  return turf.featureCollection(points);
};

export const getGateways = async() => {
  try {
    let res = await axios(`${config.gateways.server}/api/gateways/available`, {
      headers: {
        Authorization: `Bearer ${config.gateways.key}`
      }
    });

    if (res.data) {
      return res.data;
    }
  } catch (e) {
    //
  }

  return [];
};

export const getCacheData = async() => {
  try {
    let res = await axios(`${config.gateways.server}/api/caches`, {
      headers: {
        Authorization: `Bearer ${config.gateways.key}`
      }
    });

    if (res.data) {
      return res.data;
    }
  } catch (e) {
    //
  }

  return [];
};

export const getIsoSource = (rssi, settings, groupByDates = 14) => {
  if (rssi) {
    let locationSource = getLocationSource(rssi);
    let extent = turf.bbox(locationSource);
    extent[0] -= 0.01;
    extent[1] -= 0.01;
    extent[2] += 0.01;
    extent[3] += 0.01;
    let mask = turf.convex(locationSource);

    let cellWidth = settings.radius / 1000;
    let pointGrid = turf.hexGrid(extent, cellWidth, { units: 'kilometers', mask });

    pointGrid.features.forEach((e, i) => {
      e.properties.rssi = 0;
      e.properties.color = bad;
      e.properties.opacity = 0;
      e.properties.index = i;
      e.properties.description = '';
    });

    let items = pointGrid.features.map((g, i, arr) => {
      if (typeof fs !== 'undefined') {
        log(`Poly: ${Math.round(i / arr.length * 100)}% (${i + 1} / ${arr.length})`); // eslint-disable-line
      }

      let poly = turf.polygon([g.geometry.coordinates[0]]);

      return turf.pointsWithinPolygon(locationSource, poly);
    });

    items.forEach((e, i, arr) => {
      if (e.features.length === 0) {
        return;
      }

      if (typeof fs !== 'undefined') {
        log(`Items: ${Math.round(i / arr.length * 100)}% (${i + 1} / ${arr.length})`); // eslint-disable-line
      }

      let dates = e.features.map(d => d.properties.measured_at);
      dates.sort((a, b) => moment(b).unix() - moment(a).unix());

      let start = moment(dates[0]).subtract(groupByDates, 'days').unix();
      let end = moment(dates[0]).unix();
      let elements = e.features.filter(d => moment(d.properties.measured_at).unix() >= start && moment(d.properties.measured_at).unix() <= end);

      let rssi = elements.reduce((prev, curr) => prev + curr.properties.rssi, 0) / elements.length;
      let point = pointGrid.features[i];
      point.properties.rssi = rssi;
      let color;
      let opacity = 0;

      settings.colors.forEach(limit => {
        if (rssi <= limit.start && rssi > limit.end) {
          color = limit.color;
          opacity = 0.5;
        }
      });

      if (elements.length === 1 && settings.minTwoPoints) {
        opacity = 0;
      }

      let center = turf.center(point).geometry.coordinates.slice();

      point.properties.color = color;
      point.properties.opacity = opacity;
      point.properties.description = `
        <u><b>Zelle:</b></u><br><br>
        
        <b>Lat:</b> ${center[1]}<br>
        <b>Lng:</b> ${center[0]}<br>
        <b>Messpunkte:</b> ${e.features.length} (Davon ${elements.length} verwendet)<br>
        <b>RSSI:</b> ${(Math.round(rssi * 100) / 100).toFixed(2).replace('.', ',')} dBm
      `;
    });

    return pointGrid;
  }

  return turf.featureCollection([]);
};

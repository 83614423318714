<template>
  <div>
    <v-app-bar app v-if="auth" color="primary" dark>
      <div style="width: 400px; position: absolute; top: 20px;">
        <v-select label="Bereich wählen" :items="typeSelect" v-model="currentLocation" />
      </div>

      <v-spacer></v-spacer>

      <v-btn @click="allGateways" class="mr-2 black--text" color="white">Alle Gateways</v-btn>

      <v-btn @click="onlyOnline" class="mr-8 black--text" color="white">Nur physisch online Gateways</v-btn>

      <v-menu
        v-model="settings.open"
        offset-y
        width="600"
        :allow-overflow="true"
        :close-on-click="false"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" class="black--text" dark v-bind="attrs" v-on="on" :disabled="!started">
            Einstellungen
          </v-btn>
        </template>
        <div class="settingsMenu">
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="settings.showMarkers"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>Messpunkte</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="settings.showExclusiveMeasurements"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title
                >Bei einzelnem Gateway, nur solche Punkte darstellen,<br />die von sonst keinem anderen Gateway
                empfangen wurden.</v-list-item-title
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="settings.showGateways"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>Gateways</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="settings.showGateways">
              <v-list-item-action>
                <v-checkbox v-model="settings.showGatewayLables"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>Gateway-Beschriftungen</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="settings.showGateways">
              <v-list-item-action>
                <v-checkbox v-model="settings.onlyActiveGateways"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>Nur Pakete von aktiven Gateways</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="settings.showGateways">
              <v-list-item-action>
                <v-checkbox v-model="settings.minTwoPoints"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>Nur Kacheln mit min. 2 Messpunkten anzeigen</v-list-item-title>
            </v-list-item>

            <v-list-item class="border" @click.prevent.stop="() => {}" :ripple="false" :inactive="true">
              <v-list>
                <v-list-item>
                  <v-list-item-title><b>Parameter:</b></v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-text-field v-model="settings.radius" label="Radius" suffix="Meter" />
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-text-field v-model="settings.days" label="Zeitraum" suffix="Tage" />
                  </v-list-item-title>
                </v-list-item>

                <v-list-item class="border" @click.prevent.stop="() => {}" :ripple="false" :inactive="true">
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><b>Farben:</b></v-list-item-title>
                    </v-list-item>

                    <draggable v-model="settings.colors" handle=".handle">
                      <v-list-item v-for="(color, i) in settings.colors" :key="i">
                        <v-list-item-title>
                          <v-row>
                            <v-col md="4">
                              <v-text-field v-model="color.start" label="Von" />
                            </v-col>
                            <v-col md="4">
                              <v-text-field v-model="color.end" label="Bis" />
                            </v-col>

                            <v-col md="2">
                              <v-menu
                                v-model="color.open"
                                offset-y
                                :close-on-click="false"
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-sheet
                                    :color="color.color"
                                    elevation="0"
                                    height="30"
                                    width="30"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-sheet>
                                </template>
                                <v-card>
                                  <v-card-text>
                                    <v-color-picker class="ma-2" show-swatches v-model="color.color"></v-color-picker>

                                    <v-btn @click="color.open = false">Okay</v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>

                            <v-col md="1">
                              <v-icon class="handle">mdi-drag-vertical</v-icon>
                            </v-col>

                            <v-col md="1">
                              <v-btn @click="removeColor(i)" icon style="margin-left: -10px; margin-top: -8px;">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                    </draggable>

                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="addColor">Eintrag hinzufügen</v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list-item>
              </v-list>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-row v-if="started">
                  <v-col md="3">
                    <v-btn color="primary" :loading="settings.anwenden" @click="anwenden">Anwenden</v-btn>
                  </v-col>
                  <v-col md="3">
                    <v-btn color="" :disabled="settings.anwenden" @click="settings.open = false">Schließen</v-btn>
                  </v-col>
                  <v-col md="3">
                    <v-btn color="warning" :disabled="settings.anwenden" @click="save">Speichern</v-btn>
                  </v-col>
                  <v-col md="3">
                    <v-btn color="error" :disabled="settings.anwenden" @click="reset">Reset</v-btn>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col md="3">
                    <v-btn color="primary" :loading="settings.anwenden" @click="start">Starten</v-btn>
                  </v-col>
                  <v-col md="3">
                    <v-btn color="error" :disabled="settings.anwenden" @click="startWithDefaults"
                      >Mit Standardeinstellungen starten</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </v-app-bar>

    <div class="home">
      <div :class="{ map: true, full: !auth }">
        <mapbox
          access-token="pk.eyJ1IjoibmFycm93dHV4IiwiYSI6ImNpZnRnbzVzNDAxdnd0a20wdG91ZHphOWcifQ.o13TzVvrJVe2J_WriuuEIw"
          :map-options="{
            style,
            center: [8.6821, 50.1109],
            zoom: 12,
          }"
          @map-load="loaded"
          ref="mapbox"
          :key="style"
        />

        <v-card class="addressSelection">
          <v-autocomplete
            @update:search-input="searchChanged($event)"
            :items="itemSource"
            :loading="loadingAddresses"
            v-model="selectedAddress"
            :filter="() => true"
            dense
            hide-details
            label="Nach Standort suchen..."
          />
        </v-card>
        <v-card v-if="caches" class="cacheSelection">
          <v-select :items="cacheItems" v-model="selectedCache" label="Ansicht wählen" hide-details></v-select>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="loading" persistent max-width="290">
      <v-card height="40" class="pt-4">
        <v-card-text>
          <v-progress-linear v-if="progress === -1" indeterminate color="primary"></v-progress-linear>
          <v-progress-linear v-else :value="progress" color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="designSwitch">
      <v-btn-toggle v-model="style" borderless>
        <v-btn value="mapbox://styles/mapbox/streets-v12">
          <span class="hidden-sm-and-down">Karte</span>
        </v-btn>

        <v-btn value="mapbox://styles/mapbox/satellite-streets-v12">
          <span class="hidden-sm-and-down">Satellit</span>
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
/* global mapboxgl */
import Mapbox from 'mapbox-gl-vue';
import * as turf from '@turf/turf';
import {
  promisify,
  getRSSI,
  getLocationSource,
  getGateways,
  getIsoSource,
  getCacheData,
  getSelectedCache,
  getCachedLocations,
} from '../../renew/utils.js';
import draggable from 'vuedraggable';
import cloneDeep from 'clone-deep';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { mapGetters } from 'vuex';
import { AddressAutofillCore, SessionToken } from '@mapbox/search-js-core';
import _ from 'lodash';

export default {
  name: 'Home',
  data() {
    return {
      watchers: [],
      loading: false,
      started: true,
      caches: null,
      style: 'mapbox://styles/mapbox/streets-v12',
      selectedCache: null,
      isoSource: turf.featureCollection([]),
      locationSource: turf.featureCollection([]),
      filterGateways: [],
      progress: -1,
      settings: {},
      selectedPoint: null,
      hoverGateway: null,
      search: '',
      debounceSearch: null,
      autofill: null,
      sessionToken: new SessionToken(),
      suggestions: [],
      selectedAddress: null,
      loadingAddresses: false,
      addressMarker: null,
      defaultSettings: {
        anwenden: false,
        minTwoPoints: false,
        open: true,
        showGateways: true,
        showGatewayLables: false,
        showExclusiveMeasurements: false,
        showMarkers: false,
        onlyActiveGateways: true,
        radius: 200,
        days: 14,
        lastDays: 14,
        colors: [
          {
            open: false,
            start: -100,
            end: -40,
            color: '#4CAF50',
          },
          {
            open: false,
            start: -100,
            end: -105,
            color: '#9CCC65',
          },
          {
            open: false,
            start: -105,
            end: -110,
            color: '#FFEB3B',
          },
          {
            open: false,
            start: -110,
            end: -115,
            color: '#FBC02D',
          },
          {
            open: false,
            start: -115,
            end: -120,
            color: '#f5672a',
          },
          {
            open: false,
            start: -120,
            end: -160,
            color: '#b71c1c',
          },
        ],
      },
      gateways: null,
      typeSelect: [
        {
          text: 'Outdoor',
          value: 'outdoor',
        },
        {
          text: 'Indoor',
          value: 'indoor',
        },
        {
          text: 'Deep Indoor',
          value: 'deep_indoor',
        },
      ],
      currentLocation: 'outdoor',
      groupByDates: 14,
      rssi: null,
    };
  },
  components: {
    Mapbox,
    draggable,
  },
  async created() {
    this.autofill = new AddressAutofillCore({
      accessToken: 'pk.eyJ1IjoibmFycm93dHV4IiwiYSI6ImNpZnRnbzVzNDAxdnd0a20wdG91ZHphOWcifQ.o13TzVvrJVe2J_WriuuEIw',
      country: 'de',
      language: 'de',
    });
    this.debounceSearch = _.debounce(this.fetchMapboxAddress, 300);
    this.settings = cloneDeep(this.defaultSettings);
    this.caches = await getCacheData();

    if (localStorage.getItem('settings')) {
      this.settings = JSON.parse(localStorage.getItem('settings'));
    }

    this.selectedCache = this.caches[0].id;

    if (!self.auth) {
      await this.getGateways();
      this.loading = false;
      return;
    }

    await this.getGateways();

    this.loading = false;
  },
  watch: {
    style(value) {
      this.watchers.forEach((unwatch) => unwatch());
      this.$refs.mapbox.map.setStyle(value);
    },
    async selectedCache(id) {
      if (id !== -1) {
        let cache = this.caches.filter((e) => e.id === id)[0];
        this.isoSource = await getSelectedCache(id);
        this.locationSource = turf.featureCollection([]);

        this.filterGateways = [];

        this.settings.days = cache.days;
        this.settings.currentLocation = 'outdoor';
        this.settings.groupByDates = 14;
        this.settings.showMarkers = false;

        this.settings.minTwoPoints = false;
        this.settings.showExclusiveMeasurements = false;
        this.settings.radius = 200;
      }
    },
    currentLocation() {
      this.getRSSI();
    },
    'settings.showGateways'() {
      this.settings.open = false;
    },
    'settings.showGatewayLables'() {
      this.settings.open = false;
    },
    async 'settings.showMarkers'(val) {
      this.settings.open = false;

      if (this.selectedCache !== -1 && val) {
        this.locationSource = await getCachedLocations(this.selectedCache);
      }
    },
    'settings.onlyActiveGateways'() {
      this.settings.open = false;

      window.setTimeout(() => {
        if (this.selectedCache === -1) {
          this.getRSSI();
        }
      }, 100);
    },
    'settings.showExclusiveMeasurements'() {
      if (this.gateways && this.gateways.filter((g) => !this.filterGateways.includes(g.id)).length === 1) {
        window.setTimeout(() => {
          this.anwenden();
        }, 100);
      }
    },
    async selectedAddress(suggestion) {
      if (suggestion) {
        const result = await this.autofill.retrieve(suggestion, { sessionToken: this.sessionToken });
        const coordinates = result?.features[0]?.geometry?.coordinates;
        if (this.marker) {
          this.marker.remove();
        } else {
          this.marker = new mapboxgl.Marker();
        }

        this.marker.setLngLat(coordinates).addTo(this.$refs.mapbox.map);
        console.log(coordinates);
      }
    },
  },
  methods: {
    onlyOnline() {
      this.filterGateways = [
        ...this.gateways.filter((e) => !e.online).map((e) => e.id),
        ...this.gateways.filter((e) => !e.online).map((e) => e.id2),
      ].filter((e) => !!e);

      this.getRSSI();
    },
    allGateways() {
      this.filterGateways = [];

      this.getRSSI();
    },
    save() {
      localStorage.setItem('settings', JSON.stringify(this.settings));
      this.settings.open = false;

      this.$toast.open('Einstellungen gespeichert');
    },
    reset() {
      this.settings = cloneDeep(this.defaultSettings);
      this.settings.open = true;

      this.anwenden();

      this.getRSSI();
    },
    addColor() {
      this.settings.colors.push({
        open: false,
        start: 0,
        end: 0,
        color: '#000000',
      });
    },
    removeColor(i) {
      this.settings.colors = this.settings.colors.filter((_, j) => j !== i);
    },
    anwenden() {
      this.settings.anwenden = true;
      this.selectedCache = -1;

      window.setTimeout(async () => {
        if (this.settings.lastDays !== this.settings.days) {
          await this.getRSSI();
        } else {
          this.isoSource = this.getIsoSource();
        }

        this.settings.anwenden = false;
        this.settings.open = false;
      }, 300);
    },
    async getGateways() {
      this.gateways = null;

      this.gateways = await getGateways();
    },
    async loaded(map) {
      let self = this;
      const loadImage = promisify(map.loadImage.bind(map));
      let image = await loadImage(require(self.satellite ? '@/assets/gatewayWhite.png' : '@/assets/gateway.png'));
      map.addImage('gateway', image);

      let image2 = await loadImage(require('@/assets/gatewayOffline.png'));
      map.addImage('gatewayOffline', image2);
      const sources = ['markersSource', 'isoSource', 'gatewaysSource', 'gatewayLines'];

      sources.forEach((source) => {
        let sourceData = this[source];

        map.addSource(source, {
          type: 'geojson',
          data: sourceData,
        });

        this.watchers.push(this.$watch(source, (nv) => map.getSource(source).setData(nv)));
      });

      map.addLayer({
        id: 'isoData',
        type: 'fill',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': ['get', 'opacity'],
          'fill-outline-color': 'rgba(0, 0, 0, 0)',
        },
        source: 'isoSource',
      });

      map.addLayer({
        id: 'locationPoints',
        type: 'circle',
        paint: {
          'circle-radius': {
            type: 'exponential',
            stops: [
              [3, 1],
              [7, 3],
              [10, 5],
              [25, 10],
            ],
          },
          'circle-opacity': 0.5,
          'circle-stroke-width': 1,
          'circle-stroke-color': ['get', 'stroke-color'],
          'circle-color': ['get', 'color'],
        },
        source: 'markersSource',
      });

      map.addLayer({
        id: 'gatewayPoints',
        type: 'symbol',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-size': 0.25,
          'text-field': ['get', 'name'],
          'text-variable-anchor': ['top'],
          'text-radial-offset': 1.5,
          'text-justify': 'auto',
          'text-size': 12,
          'text-optional': false,
        },
        paint: {
          'text-color': ['get', 'color'],
        },
        source: 'gatewaysSource',
      });

      map.addLayer({
        id: 'gatewayLines',
        type: 'line',
        source: 'gatewayLines',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FF5252',
          'line-width': 4,
        },
      });

      map.addLayer({
        id: 'gatewayLineLables',
        type: 'symbol',
        source: 'gatewayLines',
        layout: {
          'symbol-placement': 'line-center',
          'text-font': ['Open Sans Bold'],
          'text-field': '{distance}m, RSSI: {rssi}, SNR: {snr}', // part 2 of this is how to do it
          'text-size': 18,
          'icon-allow-overlap': true,
          'text-offset': [0, 0.6],
        },
        paint: {
          'text-color': '#FF5252',
        },
      });

      map.on('contextmenu', 'gatewayPoints', function(e) {
        e.preventDefault();
        e.originalEvent.stopPropagation();

        if (!self.auth) {
          return;
        }

        let id = e.features[0].properties.id;
        let id2 = e.features[0].properties.id2;

        if (self.filterGateways.includes(id)) {
          self.filterGateways = self.filterGateways.filter((e) => e !== id && e !== id2);
        } else {
          self.filterGateways.push(id);
          if (id2) {
            self.filterGateways.push(id2);
          }
        }

        window.setTimeout(() => {
          $('.mapboxgl-popup').remove();
        }, 1);

        self.getRSSI();
      });

      map.on('click', 'gatewayPoints', function(e) {
        e.preventDefault();
        e.originalEvent.stopPropagation();

        if (!self.auth) {
          return;
        }

        let id = e.features[0].properties.id;
        self.filterGateways = [
          ...self.gateways.filter((e) => e.id !== id).map((e) => e.id),
          ...self.gateways.filter((e) => e.id !== id).map((e) => e.id2),
        ].filter((e) => !!e);

        self.getRSSI();
      });

      map.on('click', 'locationPoints', function(e) {
        e.preventDefault();
        e.originalEvent.stopPropagation();

        let coordinates = e.features[0].geometry.coordinates.slice();
        let description = e.features[0].properties.description;
        self.selectedPoint = {
          ...e.features[0].properties,
          coordinates,
        };

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup({
          anchor: 'top',
        })
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on('mouseenter', 'locationPoints', function() {
        map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'locationPoints', function() {
        map.getCanvas().style.cursor = '';
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on('mouseenter', 'gatewayPoints', function(evt) {
        map.getCanvas().style.cursor = 'pointer';

        if (self.auth) {
          self.hoverGateway = evt?.features[0]?.properties?.id;
        }
      });

      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'gatewayPoints', function() {
        map.getCanvas().style.cursor = '';
        self.hoverGateway = null;
      });

      let popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false,
      });

      map.on('contextmenu', 'isoData', function(e) {
        try {
          // Change the cursor style as a UI indicator.

          let coordinates = turf
            .center(turf.points(e.features[0].geometry.coordinates.slice()[0]))
            .geometry.coordinates.slice();
          let description = e.features[0].properties.description;
          if (!description) {
            return;
          }

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
        } catch (e) {
          //
        }
      });

      map.on('mouseenter', 'isoData', function(e) {
        let description = e.features[0].properties.description;
        if (!description) {
          return;
        }

        map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'isoData', function() {
        map.getCanvas().style.cursor = '';
      });
    },
    wait(ms) {
      return new Promise((done) => {
        window.setTimeout(done, ms);
      });
    },
    async getRSSI() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.progress = -1;

      this.rssi = await getRSSI(
        this.auth,
        this.getSettings().days,
        this.currentLocation,
        this.filterGateways,
        this.settings.onlyActiveGateways
          ? [...this.gateways.map((e) => e.id), ...this.gateways.map((e) => e.id2)].filter((e) => !!e)
          : null,
        (progress) => {
          this.progress = progress;
        }
      );

      this.locationSource = getLocationSource(this.rssi, this.getSettings(), this.gateways, this.filterGateways);

      this.isoSource = this.getIsoSource();
      this.settings.lastDays = this.settings.days;

      this.loading = false;
    },
    getSettings() {
      return this.settings;
    },
    getIsoSource() {
      return getIsoSource(this.rssi, this.getSettings(), this.groupByDates);
    },
    async fetchMapboxAddress() {
      if (!this.search) {
        this.suggestions = [];
        return;
      }

      this.loadingAddresses = true;
      this.suggestions = (await this.autofill.suggest(this.search, { sessionToken: this.sessionToken })).suggestions;
      this.loadingAddresses = false;
    },
    searchChanged(searchValue) {
      this.search = searchValue;
      this.debounceSearch();
    },
  },
  computed: {
    ...mapGetters(['auth']),
    satellite() {
      return this.style === 'mapbox://styles/mapbox/satellite-streets-v12';
    },
    cacheItems() {
      if (this.caches) {
        let additional = [];

        if (this.auth) {
          additional = [{ text: 'Individuell', value: -1 }];
        }

        return [
          ...additional,
          ...this.caches.map((e) => {
            return {
              text: `${e.days} Tage`,
              value: e.id,
            };
          }),
        ];
      }

      return [];
    },
    gatewaysSource() {
      if (this.gateways === null || !this.settings.showGateways) {
        return turf.featureCollection([]);
      }

      return turf.featureCollection(
        this.gateways.map((e) => {
          return turf.point(e.coordinates, {
            name: this.settings.showGatewayLables || this.hoverGateway === e.id ? e.name : '',
            id: e.id,
            id2: e.id2,
            icon: this.filterGateways.includes(e.id) ? 'gatewayOffline' : 'gateway',
            color: this.satellite ? '#FFFFFF' : '#000000',
          });
        })
      );
    },
    gatewayLines() {
      if (this.selectedPoint === null) {
        return turf.featureCollection([]);
      }

      let gateways = this.selectedPoint.gateways;

      if (typeof gateways === 'string') {
        gateways = JSON.parse(gateways);
      }

      return turf.featureCollection(
        gateways.map((e) => {
          return turf.lineString([e.coordinates, this.selectedPoint.coordinates], {
            distance: Math.round(turf.distance(e.coordinates, this.selectedPoint.coordinates, { units: 'meters' })),
            rssi: e.rssi,
            snr: e.snr,
          });
        })
      );
    },
    markersSource() {
      if (this.settings.showMarkers) {
        return this.locationSource;
      }

      return turf.featureCollection([]);
    },
    itemSource() {
      const items = this.suggestions.map((suggestion) => {
        return {
          text: suggestion.full_address,
          value: suggestion,
        };
      });

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.full {
  height: 100%;
}

.addressSelection {
  position: absolute;
  top: 10px;
  left: 190px;
  width: 200px;
  padding: 10px 5px 7px 5px;
}
.cacheSelection {
  position: absolute;
  top: 10px;
  right: 50px;
  width: 200px;
  padding: 10px 5px;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 40px);
  background: #e5e9ec;
}
.border {
  border: 1px solid black;
  cursor: default;
  margin: 15px;
}

.settingsMenu {
  max-height: calc(100vh - 150px) !important;
  overflow: auto;
}

.designSwitch {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>

<style>
.v-toast__text {
  font-family: 'Arial';
}
</style>
